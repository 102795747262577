import imagesloaded from 'imagesloaded';

import track from '@/helpers/track';
import { initPhotos, buildStorageMap } from '../storages-v4/show';
import { isMobile } from '@/helpers/mobile_detect';

pageLoad('boxes_show', () => {
  if (!document.querySelector('.boxes-v4-show')) { return; }

  const $storagePhoto = $(
    isMobile() ?
      '.slide_1 .photos a.photo' :
      '.slide_1 .photos a.photo:not(.size)'
  );
  if ($storagePhoto.length) {
    imagesloaded($storagePhoto.toArray(), () => initPhotos($storagePhoto));
  }

  buildStorageMap();
  track({ event: 'view_box' });
});
