import TinyUri from 'tiny-uri';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import Switcher from '@/views/switcher';
import SweetScroll from '@/helpers/sweet_scroll';
import { animatedCollapse, animatedExpand } from '@/helpers/animated';

pageLoad('agreements_show', () => {
  new Switcher('.container-profile .b-switcher');

  $('.close-agreement a').on('click', async () => {
    $('.b-switcher .inner .btn-info').click();
    (await SweetScroll.asyncInstance()).toElement(
      document.querySelector('.agreement-close-info'),
      { duration: 750, offset: -40 }
    );
  });

  $('.pin .skud-password').on('click', ({ currentTarget }) => {
    const $mask = $(currentTarget).find('.mask');
    const $value = $(currentTarget).find('.value');
    $mask.toggleClass('hidden');
    $value.toggleClass('hidden');
  });

  initModalStorageUsageRules();

  $('.bill-info').on('click', ({ currentTarget, target }) => {
    if (target.tagName === 'BUTTON' || target.tagName === 'A') { return; }

    if (currentTarget.classList.contains('is-expanded')) {
      animatedCollapse(currentTarget.nextSibling);
      currentTarget.classList.remove('is-expanded');
    } else {
      animatedExpand(currentTarget.nextSibling);
      currentTarget.classList.add('is-expanded');
    }
  });
});

pageLoad('agreements_closing', () => {
  initModalStorageUsageRules();
  $('input.confirm').on('change', ({ currentTarget }) => {
    const $checkbox = $(currentTarget);
    const $submit = $('#submit_agreement');
    $submit.prop('disabled', !$checkbox.prop('checked'));
  });
});

function initModalStorageUsageRules() {
  $('a.storage-usage-rules').on('click', () => {
    disablePageScroll();
    window.scrollTo(0, 0);
    $('.b-modal-storage-usage-rules').removeClass('hidden');
  });

  $('a.modal-close').on('click', () => {
    enablePageScroll();
    $('.b-modal-storage-usage-rules').addClass('hidden');
  });
}
