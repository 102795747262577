import trackVk from '@/helpers/track_vk';

export default function(data) {
  dataLayerPush(data);
  if (window.location.host === 'kladovkin.ru' && data.event) {
    trackVk(data.event);
  }
}

export function initUserId() {
  const userId = document.body.getAttribute('data-user_id');
  if (userId) dataLayerPush({ user_id: userId });
}

export function trackServerEvent() {
  const eventJson = document.body.getAttribute('data-track-server-event');
  if (!eventJson) return;
  try {
    const eventData = JSON.parse(eventJson);
    dataLayerPush(eventData);
  } catch (e) {
    if (window.ENV === 'development') {
      throw e;
    }
  }
}

function dataLayerPush(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
  // ---------------------------------------------------------------------------
  // TODO: Удалить это после того, как маркетинг перенастроит свою метрику.
  //       Добавил это здесь, чтобы можно было удалить это разом в одном месте.
  if (data && data.event === 'callback_form_sent') {
    window.dataLayer.push({ event: 'down_form' });
  }
  if (data && data.event === 'guest_visit_form_sent') {
    window.dataLayer.push({ event: 'guest_visit' });
  }
  // ---------------------------------------------------------------------------

  if (window.ENV === 'development') {
    console.log('window.dataLayer.push', data); // eslint-disable-line no-console
  }
}
