import IMask from 'imask';
import delay from 'delay';
import { debounce } from 'throttle-debounce';
import Turbolinks from 'turbolinks';

import SpellCheck from '@/views/spell_check';
import _modernizr from '@/vendor/modernizr'; // it detects webp support
import I18n from '@/helpers/i18n';
import track, { initUserId, trackServerEvent } from '@/helpers/track';
import trackVk from '@/helpers/track_vk';
import scheduleUpdateVh from '@/helpers/update_vh';

$(document).one('turbolinks:load', () => {
  scheduleUpdateVh();

  // https://github.com/turbolinks/turbolinks/issues/219#issuecomment-420657704
  window.addEventListener('popstate', function(event) {
    this.turbolinks_location = Turbolinks.Location.wrap(window.location);
    if (
      Turbolinks.controller.location.requestURL ===
      this.turbolinks_location.requestURL
    ) {
      return;
    }
    if (event.state != null ? event.state.turbolinks : undefined) {
      return;
    }
    if (
      (this.window_turbolinks =
        window.history.state != null ?
          window.history.state.turbolinks :
          undefined)
    ) {
      return Turbolinks.controller.historyPoppedToLocationWithRestorationIdentifier(
        this.turbolinks_location,
        this.window_turbolinks.restorationIdentifier
      );
    } else {
      return Turbolinks.controller.historyPoppedToLocationWithRestorationIdentifier(
        this.turbolinks_location,
        Turbolinks.uuid()
      );
    }
  });
});

$(document).on('turbolinks:load', () => {
  window.LOCALE = document.body.getAttribute('data-locale');
  window.IS_RTL = document.documentElement.dir === 'rtl';
  window.ENV = document.body.getAttribute('data-env');
  window['j' + 'Query'] = $; // hack to make jQuery accessible in browser console
  trackServerEvent();
  initUserId();
  $('.js-track-event-click').on('click', ({ currentTarget }) => {
    try {
      track(JSON.parse(currentTarget.getAttribute('data-track-event-data')));
    } catch (e) {
      if (window.ENV === 'development') throw e;
    }
  });

  I18n.locale = window.LOCALE;

  $('.datepicker input').each(async (_, node) => {
    const { default: Pikaday } = await import(
      /* webpackChunkName: "pikaday" */ '@/vendor/async/pikaday'
    );
    const { default: dayjs } = await import(
      /* webpackChunkName: "pikaday" */'@/helpers/dayjs'
    );

    dayjs.locale(window.LOCALE);
    const localeData = dayjs().localeData();

    new Pikaday({
      field: node,
      firstDay: 1,
      format: 'DD.MM.YYYY',
      yearRange: [1900, new Date().getFullYear() + 10],
      isRTL: window.IS_RTL,
      // minDate: new Date(),
      toString(date, _format) {
        // you should do formatting based on the passed format,
        // but we will just return 'D/M/YYYY' for simplicity
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year}`;
      },
      parse(dateString, _format) {
        // dateString is the result of `toString` method
        const parts = dateString.split('.');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      },
      i18n: {
        months: localeData.months(),
        weekdays: localeData.weekdays(),
        weekdaysShort: localeData.weekdaysShort()
      }
    });
    new IMask(node, { mask: Date });
  });

  // Возможно это уже нигде не используется
  $('.b-important-alert .close').on('click', async () => {
    $('.b-important-alert').addClass('closing');
    await delay(350);
    $('.b-important-alert').remove();
  });

  $('.b-mobile-card_selector .selector').on('click', ({ currentTarget }) => {
    const index = $(currentTarget).data('index');

    $(currentTarget).siblings().removeClass('active');
    $(currentTarget).addClass('active');

    $('.b-card').removeClass('active');
    $(`.b-card[data-index=${index}]`).addClass('active');
  });

  $('#container_wrapper .b-error-page').unwrap();

  $('.b-alert-top, b-notice-top').on('click', ({ currentTarget }) => {
    $(currentTarget).addClass('hidden');
  });

  $('.b-cookies-privacy a.button').on('click', ({ currentTarget }) => {
    $(currentTarget).parent().addClass('hidden');
  });

  $('.b-whatsapp_link a').on('click', ({ currentTarget }) => {
    window.open($(currentTarget).data('url'), '_blank');
  });

  $('.phone > a.number').on('click', () => trackVk('menu_phone'));
  initCalltouch(0);

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  $(window).on('resize', debounce(500, () => {
    // additional check to prevent fake resize events on
    if (windowWidth !== window.innerWidth || windowHeight !== window.innerHeigh) {
      $(document.body).trigger('resize:debounced');
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
    }
  }));

  new SpellCheck('.b-spell_check');
});

async function initCalltouch(iteration) {
  if (window.call_value) {
    $('#calltouch_session_id').each((_, node) => {
      const sessionId = window.call_value || 'unknown';
      $(node).val(sessionId);
    });
  } else if (iteration < 60) {
    await delay(1000);
    initCalltouch(iteration + 1);
  }
}
