import delay from 'delay';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import axios from '@/helpers/axios';
import track from '@/helpers/track';

pageLoad('agreements_new', 'agreements_create', () => {
  $('form').on('submit', ({ currentTarget }) => {
    const $form = $(currentTarget);
    const storageId = $form.find('.actions').data('ga-storage-id');

    track({ event: 'payment' });
  });

  $('.payment-methods .row .block').on('click', ({ currentTarget }) => {
    const $item = $(currentTarget);
    const $input = $('#payment_method');
    $item.parent().find('.block').removeClass('active');
    $item.addClass('active');

    if ($item.hasClass('card')) {
      $item.parents('.payment-methods').find('.info-blocks').removeClass('hidden');
      $input.val('card');
    } else {
      $item.parents('.payment-methods').find('.info-blocks').addClass('hidden');
      $input.val('bank');
    }

    const $next = $('.finish');
    const $checkbox = $('.payment-methods .agreement-confirm input');

    if ($checkbox.prop('checked')) {
      $next.removeClass('disabled');
      $next.prop('disabled', false);
    }
  });

  $('.payment-months .fast-links .item').on('click', ({ currentTarget }) => {
    const $item = $(currentTarget);
    $item.parent().find('.item').removeClass('active');
    $item.addClass('active');
    const months = $item.data('months');
    $('#paid_months').val(months);
    $('#paid_months').trigger('change');
    $('.bill-details .details .item.months .value').text(months);
  });

  $('#paid_months').on('change', recalculateAmountToPay);
  recalculateAmountToPay();

  $('.payment-methods .agreement-confirm input').on('change', ({ currentTarget }) => {
    const $checkbox = $(currentTarget);
    const $paymentMethods = $('.payment-methods .row .block.active');
    const $next = $('.finish');

    if ($checkbox.prop('checked') && $paymentMethods.length > 0) {
      $next.removeClass('disabled');
      $next.prop('disabled', false);
    } else {
      $next.addClass('disabled');
      $next.prop('disabled', true);
    }
  });

  $('a.deposit').on('click', () => {
    disablePageScroll();
    window.scrollTo(0, 0);
    $('.container-bottom').addClass('hidden');
    $('.modal-deposit').removeClass('hidden');
  });

  $('a.deposit-close').on('click', () => {
    enablePageScroll();
    $('.modal-deposit').addClass('hidden');
    $('.container-bottom').removeClass('hidden');
  });

  $('.add-product').on('click', ({ currentTarget }) => {
    const $addProduct = $(currentTarget);
    const $block = $addProduct.parents('.block-product');
    $addProduct.addClass('hidden');
    $block.find('.remove-product').removeClass('hidden');
    $block.addClass('active');
    $('#paid_months').trigger('change');
    $('#simple_lock_product_id').val($block.data('product-id'));
  });

  $('.remove-product').on('click', ({ currentTarget }) => {
    const $removeProduct = $(currentTarget);
    const $block = $removeProduct.parents('.block-product');
    $removeProduct.addClass('hidden');
    $block.find('.add-product').removeClass('hidden');
    $block.removeClass('active');
    $('#paid_months').trigger('change');
    $('#simple_lock_product_id').val('');
  });

  $('#coupon_code')
    .on('change', () => couponError(false))
    .on('keyup paste change', async ({ currentTarget }) => {
      await delay();
      $('.submit-coupon-code').toggleClass('hidden', !currentTarget.value);
    });

  $('.submit-coupon-code').on('click', async ({ currentTarget }) => {
    const $couponCode = $('#coupon_code');

    currentTarget.disabled = true;
    const coupon = (
      await axios.post(
        currentTarget.getAttribute('data-url'),
        { code: $couponCode.val() }
      )
    ).data.coupon;

    couponError(!coupon);

    if (coupon) {
      $('input[name=coupon_code]').val($couponCode.val());
      $couponCode.prop('disabled', true);
      $('#paid_months').data('coupon', coupon);
      recalculateAmountToPay();
    } else {
      currentTarget.disabled = false;
    }
  });
});

function couponError(isError) {
  $('.b-input.coupon-code').toggleClass('field_with_errors', isError);
}

function recalculateAmountToPay() {
  const $months = $('#paid_months');
  const months = parseInt($months.val());
  const monthlyAmountWoDiscounts = parseFloat($months.data('monthly-amount-wo-discounts'));
  const depositAmount = parseFloat($months.data('deposit-amount'));

  const coupon = $months.data('coupon');

  const intervalMonthsDiscounts = $months.data('interval_months-discounts');
  const permanentDiscountPercent = parseFloat($months.data('permanent_discount-percent'));

  const currencyUnit = $('.bill-details .details .additional-products').data('currency-unit');

  let productsAmount = 0;
  $('.block-product.active').each((_index, item) => {
    productsAmount += $(item).data('price') || 0;
  });

  const calculatedTotalMonthlyAmount = calculateTotalMonthlyAmount({
    months,
    monthlyAmountWoDiscounts,
    intervalMonthsDiscounts,
    coupon,
    permanentDiscountPercent
  });
  const totalAmount = calculatedTotalMonthlyAmount +
    depositAmount + productsAmount;
  const totalDiscount = ((monthlyAmountWoDiscounts * months) - calculatedTotalMonthlyAmount);

  $('.bill-details .details .item.months .value').text(months);
  $('.bill-details .total .amount').text(
    formatAmount(totalAmount, currencyUnit)
  );
  $('.bill-details .details .item.discount .value').text(
    formatAmount(totalDiscount, currencyUnit)
  );
  $('.bill-details .details .additional-products .value').text(
    formatAmount(productsAmount, currencyUnit)
  );

  $months.parents('.payment-months').find('.item').removeClass('active');
  if (months === '6') {
    $months.parents('.payment-months').find('.item.months_6').addClass('active');
  } else if (months === '12') {
    $months.parents('.payment-months').find('.item.months_12').addClass('active');
  }
}

function calculateTotalMonthlyAmount({
  months,
  monthlyAmountWoDiscounts,
  intervalMonthsDiscounts,
  coupon,
  permanentDiscountPercent
}) {
  return [...Array(months)] // do not replace with just Array(months) since such array won't be iteratable
    .map((_, index) => {
      const month = index + 1;
      const intervalMonthsDiscountPercent = intervalMonthsDiscounts
        .filter(({ interval_months }) => month <= interval_months)
        .map(({ percent }) => parseFloat(percent))
        .sum();
      const monthlyAmountWithIntervalMonthsDiscounts = applyPercentDiscount(
        monthlyAmountWoDiscounts,
        intervalMonthsDiscountPercent + permanentDiscountPercent
      );

      return month == 1 ?
        applyCoupon(monthlyAmountWithIntervalMonthsDiscounts, coupon) :
        monthlyAmountWithIntervalMonthsDiscounts;
    })
    .sum();
}

function formatAmount(value, currencyUnit) {
  if (!value) { return '—'; }

  const formattedValue = String(value)
    .replace(/\.0+$/, '')
    .replace(/\d(?=(\d{3})+(?:\.|$))/g, '$& ');

  return `${formattedValue} ${currencyUnit}`;
}

function applyCoupon(amount, coupon) {
  if (!coupon) { return amount; }

  return coupon.kind === 'fixed' ?
    applyFixedDiscount(amount, coupon.value) :
    applyPercentDiscount(amount, coupon.value);
}

function applyFixedDiscount(amount, discount) {
  return amount - parseFloat(discount);
}

function applyPercentDiscount(amount, discount) {
  return amount - Math.ceil(amount / 100.0 * parseFloat(discount));
}

function couponDiscount(amount, coupon) {
  return amount - applyCoupon(amount, coupon);
}
